import { EventType, pushEvent } from "./index";

/**
 * Null Searches
 */
export const event35 = (query: string) => {
  const eventLabel = `${query} - 0`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.siteSearch,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Interest"
  });
};
