import React, { FunctionComponent } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./styles.scss";

const ProductListingSkeleton: FunctionComponent = () => {
  return (
    <div className="skeleton-wrapper">
      <div className="skeleton-mobile">
        <div style={{ width: "100%", marginTop: 70, marginBottom: 35 }}>
          <Skeleton count={2}></Skeleton>
        </div>
        <div
          style={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}
        >
          <div style={{ width: "50%", paddingRight: 7.5 }}>
            <Skeleton height={200}></Skeleton>
            <div style={{ marginTop: 10 }}>
              <Skeleton count={2} />
            </div>
          </div>
          <div style={{ width: "50%", paddingLeft: 7.5 }}>
            <Skeleton height={200}></Skeleton>
            <div style={{ marginTop: 10 }}>
              <Skeleton count={2} />
            </div>
          </div>
          <div style={{ width: "50%", paddingRight: 7.5, paddingTop: 7.5 }}>
            <Skeleton height={200}></Skeleton>
            <div style={{ marginTop: 10 }}>
              <Skeleton count={2} />
            </div>
          </div>
          <div style={{ width: "50%", paddingLeft: 7.5, paddingTop: 7.5 }}>
            <Skeleton height={200}></Skeleton>
            <div style={{ marginTop: 10 }}>
              <Skeleton count={2} />
            </div>
          </div>
        </div>
      </div>
      <div className="skeleton-desktop">
        <div style={{ width: 500, marginBottom: 70 }}>
          <Skeleton></Skeleton>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "18%" }}>
            <div style={{ marginBottom: 25 }}>
              <Skeleton></Skeleton>
            </div>
            <div style={{ width: "75%" }}>
              <Skeleton count={5}></Skeleton>
            </div>
            <div style={{ marginBottom: 25 }}>
              <Skeleton></Skeleton>
            </div>
            <div style={{ width: "75%" }}>
              <Skeleton count={5}></Skeleton>
            </div>
            <div style={{ marginBottom: 15, marginTop: 15 }}>
              <Skeleton></Skeleton>
            </div>
            <div style={{ marginBottom: 15 }}>
              <Skeleton></Skeleton>
            </div>
          </div>
          <div style={{ width: "77%" }}>
            <div style={{ marginBottom: 25 }}>
              <Skeleton></Skeleton>
            </div>
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
              <div style={{ width: 300, padding: 15 }}>
                <Skeleton height={300}></Skeleton>
                <div style={{ marginTop: 25 }}>
                  <Skeleton count={2}></Skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductListingSkeleton;
