import { EventType, pushEvent } from "./index";

/**
 * Internal Searches
 */
export const event34 = (query: string, resultNo: string) => {
  const eventLabel = `${query} - ${resultNo}`;

  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.siteSearch,
      eventLabel: eventLabel,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Interest"
  });
};
