import React from "react";
import { graphql, PageProps } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import Container from "react-bootstrap/Container";
import SEO from "../../components/Seo";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import { withPrefix } from "gatsby";
import SearchItems from "../../components/SearchItems";
import "./styles.scss";

export const query = graphql`
  query sanitySearchItemsAll($_id: String, $language: String, $market: String) {
    searchItems: sanitySearchItems(_id: { eq: $_id }) {
      ...SearchItemsPageFieldsBasic
    }
    ...LocalizedContext
  }
`;

const SearchV2 = (props: any) => {
  const searchItemsPage = props.data.searchItems;

  const { siteUrl } = useSiteMetadata();
  const localizedPath = withPrefix(getLocalizedPath(searchItemsPage.slug.current, searchItemsPage._id));

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={searchItemsPage.seo.metaTitle}
      pageType={"search"}
      contentType={"Search"}
      cssClass={"search"}
    >
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <StructuredData customData={searchItemsPage.seo.jsonld} />
      <SEO title={searchItemsPage.seo.metaTitle} description={searchItemsPage.seo.metaDescription} />
      <div>
        <Container fluid>
          <div className="page_filterProducts">
            <h1>
              {searchItemsPage.searchItemsName.searchItemsHeadingOne}
              <span> {searchItemsPage.searchItemsName.searchItemsHeadingTwo}</span>
            </h1>
            <SearchItems props={searchItemsPage} />
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SearchV2;
