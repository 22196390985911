import React, { useContext, useEffect, useState, useRef } from "react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { Search } from "../../images/icons/search";
import { Close } from "../../images/icons/close";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { LocalizedContext } from "../../services/LocalizedContextService";
import algoliasearch from "algoliasearch/lite";
import { graphql, useStaticQuery } from "gatsby";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import ProductRatings from "../ProductRatings";
import ProductRatingsAndReviewsScript from "../ProductRatingsAndReviewsScript";
import Accessibility from "../Accessibility";
import SearchListingSkeleton from "./Skeleton";
import Filters from "./Filters";

import "./styles.scss";
import { ImageInterface } from "../../types/SanityTypes";
import { event34 } from "../../analytics/event34";
import { event35 } from "../../analytics/event35";

export interface SearchItemsInterface {
  props: {
    searchItemsLabels?: {
      unsuccessfulMessage: string;
      applyFilterLabel: string;
      clearFilterLabel: string;
      filterByLabel: string;
      loadMoreLabel: string;
      resultsLabel: string;
      searchLabel: string;
      highlightsName: string;
      highlightsMessage: string;
      filterNoResultsMessage: string;
      searchCategory: string;
    };
    searchItemsCategories: {
      tagsCategory: Array<categoryTag>;
    };
    slug: string;
  };
}

type categoryTag = {
  labelName: string;
  name: string;
};

type SanitySearchItemTag = {
  tagCategory: {
    name: string;
  };
  tagName: string;
};

type filterObj = {
  category?: string;
  array: Array<string>;
  label: string;
};

const SearchItemsListing = (props: SearchItemsInterface) => {
  const { searchItemsLabels, searchItemsCategories } = props.props;
  const { language, sanityProductLandingPage, sanityLabels, sanitySearch, sanityAccessibility, market } = useContext(LocalizedContext);
  const { close } = sanityAccessibility || {};
  const { algoliaAppId, algoliaIndex, algoliaSearchApiKey, sanityId, sanityDataset } = useSiteMetadata(language);
  const urlBuilder = sanityImageUrlBuilder({
    projectId: sanityId,
    dataset: sanityDataset
  });
  const categoriesArray = searchItemsCategories?.tagsCategory;

  let urlParams = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null;
  const urlTags = urlParams
    ? urlParams.getAll("tag").map(i => {
      return i.endsWith("/") ? i.slice(0, -1) : i;
    })
    : null;
  const urlPageTypeTags = urlParams
    ? urlParams.getAll("pageType").map(i => {
      return i.endsWith("/") ? i.slice(0, -1) : i;
    })
    : null;

  const typeTags = useStaticQuery(graphql`
    query TagTypeV2 {
      allSanityTag {
        nodes {
          market
          tagName
          tagCategory {
            name
          }
        }
      }
      allSanityTagV2 {
        nodes {
          market
          tagCategory
          tagName
        }
      }
    }
  `);

  const [activeFilters, setActiveFilters] = useState<any>(urlTags);
  const [activePageTypeFilters, setActivePageTypeFilters] = useState<any>(urlPageTypeTags);
  const [searchInput, setSearchInput] = useState<string>("");
  const [searchResult, setSearchResult] = useState<any>([]);
  const [facets, setFacets] = useState<any>({});
  const [page, setPage] = useState<number>(0);
  const [nbPage, setNbPage] = useState<number>(0);
  const [results, setResults] = useState<number>(0);
  const [resetListing, setResetListing] = useState<boolean>(true);
  const [emptyResult, setEmptyResult] = useState<any>([]);
  const [noSearchResults, setNoSearchResults] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(991);
  const [mobile, setMobile] = useState<boolean>(false);
  const [searchChange, setSearchChange] = useState<string>("");
  const [activeSearch, setActiveSearch] = useState<boolean>(false);
  const resultRef = useRef(null);
  const itemRef = useRef(null);
  const eventTrigger = results || searchInput

  const newFiltersArr = [
    searchItemsLabels?.clearFilterLabel,
    ...new Set(activeFilters),
    ...new Set(activePageTypeFilters)
  ];

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    fetchEmptyData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [activeFilters, activePageTypeFilters, page, searchInput]);


  useEffect(() => {
    results !== 0 ? event34(searchInput, results.toString()) : event35(searchInput);
  }, [eventTrigger]);

  useEffect(() => {
    if (resultRef.current && activeSearch && page === 0) {
      setTimeout(() => {
        //@ts-ignore
        resultRef?.current?.focus();
      }, 500)
    }
  }, [searchResult]);

  const filterSequence: Array<string> = ["pageType"];
  const tagsList: Array<filterObj> = [
    {
      category: "pageType",
      array: ["product", "article", "productV2", "landingPage"],
      label: searchItemsLabels?.searchCategory ? searchItemsLabels?.searchCategory : 'Type'
    }
  ];
  if (categoriesArray) {
    categoriesArray.forEach((category: { name: string; labelName: string }) => {
      const value = {
        category: category.name,
        array: [],
        label: category.labelName
      };
      tagsList.push(value);
      filterSequence.push(category.name);
    });
  }

  const filteredTagsV2= typeTags.allSanityTagV2.nodes.filter((node: any) => node.market === market);
  const filteredTags= typeTags.allSanityTag.nodes.filter((node: any) => node.market === market);

  filteredTagsV2.forEach((tags: SanitySearchItemTag) => {
    //@ts-ignore
    const index = filterSequence.indexOf(tags.tagCategory);
    if (index !== -1) {
      tagsList[index].array.push(tags?.tagName);
    }
  });
  filteredTags.forEach((tags: SanitySearchItemTag) => {
    const index = filterSequence.indexOf(tags.tagCategory.name);
    if (index !== -1) {
      tagsList[index].array.push(tags?.tagName);
    }
  });

  const fetchData = () => {
    const client = algoliasearch(algoliaAppId, algoliaSearchApiKey);
    const index = client.initIndex(algoliaIndex);
    const array: Array<string> = [];
    const arrayPageType: Array<string> = [];
    const pageType = ["pageType:product", "pageType:productV2", "pageType:article", "pageType:landingPage", "pageType:newsletterSignUpEpsilon"];
    const lang = ["lang:" + language];
    activeFilters?.map((value: any) => {
      array.push("tags.name:" + value);
    });
    activePageTypeFilters?.map((value: any) => {
      arrayPageType.push("pageType:" + value);
    });
    //@ts-ignore
    array.push(pageType);
    //@ts-ignore
    array.push(lang);
    //@ts-ignore
    array.push(arrayPageType);

    const newActiveFiltersArr = [...new Set(array)];

    index
      .search(searchInput, {
        getRankingInfo: true,
        analytics: false,
        enableABTest: false,
        hitsPerPage: window.innerWidth > 991 ? 9 : 8,
        snippetEllipsisText: "…",
        explain: "*",
        maxValuesPerFacet: 100,
        page: page,
        facets: ["tags.name", "pageType"],
        //@ts-ignore
        facetFilters: newActiveFiltersArr
      })
      .then(result => {
        if (resetListing) {
          setResults(result.nbHits);
          setSearchResult(result.hits);
          setResetListing(false);
          result.nbHits === 0 ? setNoSearchResults(true) : setNoSearchResults(false);
        } else {
          setSearchResult([...searchResult, ...result.hits]);
        }
        const normalized = {};
        result.facets && result.facets["tags.name"] !== undefined && Object.keys(result.facets["tags.name"]).forEach((key: string) => {
          // @ts-ignore
          normalized[key.toLowerCase()] = result.facets["tags.name"][key];
        })
        // @ts-ignore
        result.facets["tags.name"] = normalized;

        setFacets(result.facets);
        setNbPage(result.nbPages);
      })
      .catch(erro => {
        console.log("hits: ", erro);
      });
  };

  const fetchEmptyData = () => {
    const client = algoliasearch(algoliaAppId, algoliaSearchApiKey);
    const index = client.initIndex(algoliaIndex);
    const array: Array<string> = [];
    const pageType = ["pageType:product", "pageType:productV2", "pageType:article"];
    const lang = ["lang:" + language];
    activeFilters?.map((value: any) => {
      array.push("pageType:" + value);
    });
    //@ts-ignore
    array.push(pageType);
    //@ts-ignore
    array.push(lang);

    index
      .search("", {
        getRankingInfo: true,
        analytics: false,
        enableABTest: false,
        hitsPerPage: 8,
        snippetEllipsisText: "…",
        explain: "*",
        maxValuesPerFacet: 8,
        page: page,
        facets: ["tags.name", "pageType"],
        facetFilters: array
      })
      .then(result => {
        setEmptyResult(result.hits);
      })
      .catch(erro => {
        console.log("hits: ", erro);
      });
  };

  const handleInputChange = (value: string) => {
    setPage(0);
    setResetListing(true);
    setActiveSearch(true);
    if (!activeFilters?.includes(value)) {
      setActiveFilters((oldActiveFilters: any) => [...oldActiveFilters, value]);
      urlParams?.append("tag", value);
    } else {
      setActiveFilters((oldActiveFilters: any) => oldActiveFilters.filter((item: any) => item !== value));
      const updatedUrlParams = new URLSearchParams("");
      urlParams?.forEach(function (item, key) {
        if (item !== value) {
          updatedUrlParams.append(key, item);
        }
      });
      urlParams = updatedUrlParams;
    }
    window.history.pushState("", "", "?" + urlParams?.toString());
  };

  const handleInputChangePageType = (value: string) => {
    setPage(0);
    setResetListing(true);
    setActiveSearch(true);
    if (!activePageTypeFilters?.includes(value)) {
      setActivePageTypeFilters((oldActiveFilters: any) => [...oldActiveFilters, value]);
      urlParams?.append("pageType", value);
    } else {
      setActivePageTypeFilters((oldActiveFilters: any) => oldActiveFilters.filter((item: any) => item !== value));
      const updatedUrlParams = new URLSearchParams("");
      urlParams?.forEach(function (item, key) {
        if (item !== value) {
          updatedUrlParams.append(key, item);
        }
      });
      urlParams = updatedUrlParams;
    }
    window.history.pushState("", "", "?" + urlParams?.toString());
  };

  const handleRemoveAll = () => {
    setResetListing(true);
    setActiveFilters([]);
    setActivePageTypeFilters([]);
    window.history.pushState("", "", "?");
  };

  const clearSearch = () => {
    setPage(0);
    setResetListing(true);
    setSearchInput("");
    setSearchChange("");
    setSearchResult("");
  };

  const handleSearch = () => {
    if (searchChange !== searchInput) {
      setActiveSearch(true);
      setPage(0);
      setSearchInput(searchChange);
      const searchArr = searchResult.filter((item: { title: string }) => {
        return item.title.search(searchInput) != -1;
      });
      setSearchResult(searchArr);
      setResetListing(true);
    }
  };

  const handleLoadMore = () => {
    //@ts-ignore
    itemRef.current && itemRef?.current?.focus();
    setPage(page + 1)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getItemLabel = (item: string) => {
    if (item === "product" || item === "productV2") {
      return sanityLabels?.searchLabels?.product;
    }
    if (item === "article") {
      return sanityLabels?.searchLabels?.article;
    }
    if (item === "landingPage") {
      return sanityLabels?.searchLabels?.landingPage ?? "Campaign";
    }

    return item;
  }

  const renderListing = (data: boolean) => {
    const newArr = data ? searchResult : emptyResult;
    return (
      <>
        {newArr &&
          newArr.map((el: any, index: number) => {
            let {
              // eslint-disable-next-line prefer-const
              image, slug, title, pageType, parentSection, upc
            }: {
              slug: { current: string };
              title: string;
              image: { alt: string; asset: { _ref: string } };
              pageType: string;
              parentSection: {
                parentSection: any;
                name: string;
                slug: { current: string }
              };
              upc: string;
            } = el;

            image = image ? image : (sanitySearch?._rawPlaceholder as ImageInterface);

            return (
              <div key={index} className={"products-container-card"}>
                <a
                  ref={index === newArr.length - 1 ? itemRef : null}
                  href={
                    pageType === "product" || pageType === "productV2"
                      ? `../${sanityProductLandingPage?.slug.current}/${slug.current}`
                      : parentSection && Object.entries(parentSection).length > 0
                      ? `../${
                          parentSection?.parentSection && Object.entries(parentSection).length > 0
                            ? parentSection?.parentSection.slug.current +
                              "/" +
                              parentSection?.slug.current +
                              "/" +
                              slug?.current
                            : parentSection?.slug.current + "/" + slug?.current
                        }`
                      : `../${slug?.current}`
                  }
                >
                  <picture>
                    <source
                      media={"(min-width: 1200px)"}
                      srcSet={
                        urlBuilder
                          .image(image?.asset?._ref)
                          .auto("format")
                          .quality(80)
                          .width(286)
                          .height(286)
                          .format("webp")
                          .url() as string
                      }
                    />
                    <img
                      src={
                        urlBuilder
                          .image(image?.asset?._ref)
                          .auto("format")
                          .quality(80)
                          .width(265)
                          .height(265)
                          .format("webp")
                          .url() as string
                      }
                      alt={image?.alt}
                    />
                  </picture>
                  <h2>{title}</h2>
                </a>
                {pageType === "product" && <ProductRatings productName={title} upc={upc} template="pdp" />}
                {pageType === "productV2" && <ProductRatings productName={title} upc={upc} template="pdp" />}
              </div>
            );
          })}
      </>
    );
  };

  const RenderListingMobile = () => {
    if (searchResult || []) {
      return (
        <>
          <Row>
            <Col lg="3">
              {noSearchResults ? null : (
                <div className="filter-container product-filter-body-results mobile-filter">
                  <div className="mobile-filter-spans">
                    <button
                      type="button"
                      tabIndex={0}
                      className="product-filter-body-results-span"
                      onClick={() => setMobile(!mobile)}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                      >
                        <path
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="32"
                          d="M32 144h448M112 256h288M208 368h96"
                        ></path>
                      </svg>
                      {searchItemsLabels?.filterByLabel}
                    </button>
                    <span tabIndex={-1} ref={resultRef} className="product-filter-body-results-span">
                      {searchInput.length === 0 && activeFilters?.length <= 0 && activePageTypeFilters?.length <= 0
                        ? `${searchItemsLabels?.highlightsName}`
                        : `${results} ${searchItemsLabels?.resultsLabel}`}
                    </span>
                  </div>
                  <div className={`mobile-filter-box ${mobile ? "true" : "false"}`}>
                    <div className="mobile-filter-box-top">
                      <div className="mobile-filter-box-top-first">
                        <button type="button" aria-label="Toggle navigation" onClick={() => setMobile(!mobile)}>
                          <svg width="40px" height="40px" viewBox="0 0 74 74" version="1.1">
                            <title>{close}</title>
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g transform="translate(-14.000000, -162.000000)">
                                <rect x="0" y="0"></rect>
                                <g
                                  transform="translate(51.000000, 199.000000) rotate(-225.000000) translate(-51.000000, -199.000000) translate(26.000000, 174.000000)"
                                  fill="#D8D8D8"
                                  stroke="var(--primary-color)"
                                  strokeLinecap="round"
                                  strokeWidth="4"
                                >
                                  <line x1="25.0681974" y1="0.325957817" x2="24.4318013" y2="49.3284565"></line>
                                  <line x1="49.2512487" y1="24.5090091" x2="0.885146112" y2="24.5090091"></line>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                      </div>
                      <div className="mobile-filter-box-top-second">
                        <span>{searchItemsLabels?.filterByLabel}</span>
                      </div>
                      <button className="mobile-filter-btn btn-primary" onClick={() => setMobile(!mobile)}>
                        {searchItemsLabels?.applyFilterLabel}
                      </button>
                    </div>
                    <div className="mobile-filter-box-body">
                      <Filters
                        width={width}
                        tagsList={tagsList}
                        facets={facets}
                        handleInputChangePageType={handleInputChangePageType}
                        sanityLabels={sanityLabels}
                        urlPageTypeTags={urlPageTypeTags}
                        urlTags={urlTags}
                        handleInputChange={handleInputChange}
                        filterNoResultsMessage={searchItemsLabels?.filterNoResultsMessage}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <Col lg="9">
              <div className="products-container product-filter-body-results">
                <div className="products-container product-filter-body-results-btn mobile-filter">
                  {activeFilters === null || activePageTypeFilters === null || newFiltersArr.length === 1
                    ? null
                    : newFiltersArr.map((item: any, index: number) => {
                      if (index === 0) {
                        return (
                          <button key={'btn-' + index} className="btn-primary" onClick={handleRemoveAll}>
                            {searchItemsLabels?.clearFilterLabel}
                          </button>
                        );
                      }
                      return (
                        <button
                          key={'btn-' + index}
                          className="results-btn"
                          onClick={
                            item === "article" || item === "product" || item === "productV2" || item === "landingPage"
                              ? () => handleInputChangePageType(item)
                              : () => handleInputChange(item)
                          }
                        >
                          {getItemLabel(item)}
                          <svg width="20px" height="20px" viewBox="0 0 74 74" version="1.1">
                            <title>{close}</title>
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <g transform="translate(-14.000000, -162.000000)">
                                <rect x="0" y="0"></rect>
                                <g
                                  transform="translate(51.000000, 199.000000) rotate(-225.000000) translate(-51.000000, -199.000000) translate(26.000000, 174.000000)"
                                  fill="#D8D8D8"
                                  stroke="var(--primary-color)"
                                  strokeLinecap="round"
                                  strokeWidth="4"
                                >
                                  <line x1="25.0681974" y1="0.325957817" x2="24.4318013" y2="49.3284565"></line>
                                  <line x1="49.2512487" y1="24.5090091" x2="0.885146112" y2="24.5090091"></line>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                      );
                    })}
                </div>
                <div className="product-filter-body-results-div">{renderListing(true)}</div>
              </div>
            </Col>
          </Row>
        </>
      );
    } else {
      return null;
    }
  };

  const RenderListingHighlights = () => {
    return <div className="product-filter-body-results-div no-results">{renderListing(false)}</div>;
  };

  if (searchResult || []) {
    return (
      <div className="product-filter" data-testid="product-listing-filter">
        <ProductRatingsAndReviewsScript />
        {width <= 991 ? (
          <div className="product-filter-search search-icon">
            <div className={"search-icon-false"}>
              <label>Search</label>
            </div>
            <div className={"search-icon-true"}>
              <input
                onChange={event => setSearchChange(event.target.value)}
                type={"text"}
                placeholder={searchItemsLabels?.searchLabel}
                onKeyDown={event => handleKeyDown(event)}
                value={searchChange}
              />
              <button
                className={searchChange !== "" ? "clear-button-active" : "clear-button-inactive"}
                onClick={() => clearSearch()}
              >
                <Close />
                <Accessibility location="Clear Search" />
              </button>
              <button onClick={() => handleSearch()}>
                <Search />
                <Accessibility location="Search icon" />
              </button>
            </div>
          </div>
        ) : (
          <div className="product-filter-search">
            <label>Search</label>
            <input
              onChange={event => setSearchChange(event.target.value)}
              type={"text"}
              placeholder={searchItemsLabels?.searchLabel}
              onKeyDown={event => handleKeyDown(event)}
              value={searchChange}
            />
            <button
              className={searchChange !== "" ? "clear-button-active" : "clear-button-inactive"}
              onClick={() => clearSearch()}
            >
              <Close />
              <Accessibility location="Clear Search" />
            </button>
            <button onClick={() => handleSearch()}>
              <Search />
              <Accessibility location="Search icon" />
            </button>
          </div>
        )}
        {results === 0 && !noSearchResults ? (
          <SearchListingSkeleton />
        ) : noSearchResults ? (
          <div className="product-filter-message">
            <p>{searchItemsLabels?.unsuccessfulMessage}</p>
            <div className="more-items">
              <span>{searchItemsLabels?.highlightsMessage}</span>
              <RenderListingHighlights />
            </div>
          </div>
        ) : (
          <div className="product-filter-body">
            {width > 991 ? (
              <Row>
                <Col lg="3">
                  <div className="filter-container product-filter-body-results">
                    <span className="product-filter-body-results-span">{searchItemsLabels?.filterByLabel}</span>
                    <Filters
                      width={width}
                      tagsList={tagsList}
                      facets={facets}
                      handleInputChangePageType={handleInputChangePageType}
                      handleInputChange={handleInputChange}
                      sanityLabels={sanityLabels}
                      urlPageTypeTags={urlPageTypeTags}
                      urlTags={urlTags}
                      filterNoResultsMessage={searchItemsLabels?.filterNoResultsMessage}
                    />
                  </div>
                </Col>
                <Col lg="9">
                  <div className="products-container product-filter-body-results">
                    <span tabIndex={-1} ref={resultRef} className="product-filter-body-results-span">
                      {searchInput.length === 0 && activeFilters?.length <= 0 && activePageTypeFilters?.length <= 0
                        ? `${searchItemsLabels?.highlightsName}`
                        : `${results} ${searchItemsLabels?.resultsLabel}`}
                    </span>
                    <div className="products-container product-filter-body-results-btn">
                      {activeFilters === null || activePageTypeFilters === null || newFiltersArr.length === 1
                        ? null
                        : newFiltersArr.map((item: any, index: number) => {
                          if (index === 0) {
                            return (
                              <button key={index} className="btn-primary" onClick={handleRemoveAll}>
                                {searchItemsLabels?.clearFilterLabel}
                              </button>
                            );
                          }
                          return (
                            <button
                              key={index}
                              className="results-btn"
                              onClick={
                                item === "article" || item === "product" || item === "productV2" || item === "landingPage"
                                  ? () => handleInputChangePageType(item)
                                  : () => handleInputChange(item)
                              }
                            >
                              {getItemLabel(item)}
                              <svg width="30px" height="30px" viewBox="0 0 74 74" version="1.1">
                                <title>{close}</title>
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <g transform="translate(-14.000000, -162.000000)">
                                    <rect x="0" y="0"></rect>
                                    <g
                                      transform="translate(51.000000, 199.000000) rotate(-225.000000) translate(-51.000000, -199.000000) translate(26.000000, 174.000000)"
                                      fill="#D8D8D8"
                                      stroke="var(--primary-color)"
                                      strokeLinecap="round"
                                      strokeWidth="4"
                                    >
                                      <line x1="25.0681974" y1="0.325957817" x2="24.4318013" y2="49.3284565"></line>
                                      <line x1="49.2512487" y1="24.5090091" x2="0.885146112" y2="24.5090091"></line>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                              <Accessibility location={`Remove ${item?.charAt(0).toUpperCase()}${item?.slice(1)}`} />
                            </button>
                          );
                        })}
                    </div>
                    {<div className="product-filter-body-results-div">{renderListing(true)}</div>}
                  </div>
                </Col>
              </Row>
            ) : (
              <RenderListingMobile />
            )}
            {searchInput.length === 0 && activeFilters?.length <= 0 && activePageTypeFilters?.length <= 0 ? null : (
              <Row>
                <Col className={"offset-lg-3 loadmore-container"}>
                  {nbPage - 1 > page && (
                    <section className="cta-block" data-testid="cta-block">
                      <div className="ball">
                        <button className="cta-block-link" onClick={() => handleLoadMore()}>
                          <div>
                            <span>{searchItemsLabels?.loadMoreLabel}</span>
                            <svg width="30px" height="30px" viewBox="0 0 512.000000 512.000000">
                              <g transform="translate(0, 512) scale(0.100000,-0.100000)">
                                <path d="M2950 4382 c-47 -52 -91 -103 -97 -113 -11 -15 89 -107 857 -785 l869 -769 -2289 -3 -2290 -2 0 -150 0 -150 2290 -2 2289 -3 -869 -769 c-768 -678 -868 -770 -857 -785 6 -10 50 -61 97 -113 82 -93 85 -96 105 -80 12 10 481 424 1043 921 l1022 904 0 77 0 77 -1022 904 c-562 497 -1031 911 -1043 921 -20 16 -23 13 -105 -80z" />
                              </g>
                            </svg>
                          </div>
                        </button>
                      </div>
                    </section>
                  )}
                </Col>
              </Row>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default SearchItemsListing;
