import React, { useContext, useState } from "react";
import { LocalizedContext } from "../../../services/LocalizedContextService";
import Accessibility from "../../Accessibility";
import "./styles.scss";

type FiltersProps = {
  width: number;
  tagsList: any;
  facets: any;
  handleInputChangePageType: any;
  handleInputChange: any;
  sanityLabels: any;
  urlPageTypeTags: any;
  urlTags: any;
  filterNoResultsMessage: string | undefined;
};

const Filters = (props: FiltersProps) => {
  const {
    width,
    tagsList,
    facets,
    handleInputChangePageType,
    handleInputChange,
    sanityLabels,
    urlPageTypeTags,
    urlTags,
    filterNoResultsMessage
  } = props;
  const [accordion, setAcccordion] = useState<any>({});
  const { sanityAccessibility } = useContext(LocalizedContext);
  const { showContent, hideContent } = sanityAccessibility || {};

  const sortTags: any = [];

  tagsList.forEach((category: any) => {
    try {
      const pageTypeArr = category?.array?.reduce(
        (ac: any, a: string) => ({ ...ac, [a]: facets["pageType"][a] === undefined ? 0 : facets["pageType"][a] }),
        {}
      );
      const tagArr = category?.array?.reduce(
        (ac: any, a: string) => ({ ...ac, [a.toLowerCase()]: facets["tags.name"][a.toLowerCase()] === undefined ? 0 : facets["tags.name"][a.toLowerCase()] }),
        {}
      );
      const newArr = [pageTypeArr, tagArr];
      sortTags.push({ label: category.label, tags: newArr, category: category.category });
    } catch (e) {
      console.log(e);
    }
  });

  const handleAccordion = (index: number) => {
    setAcccordion((state: any) => ({
      ...state,
      [index]: !state[index]
    }));
  };

  const getItemLabel = (item: string) => {
    if (item === "product" || item === "productV2") {
      return sanityLabels?.searchLabels?.product;
    }
    if (item === "article") {
      return sanityLabels?.searchLabels?.article;
    }
    if (item === "landingPage") {
      return sanityLabels?.searchLabels?.landingPage ?? "Campaign";
    }

    return item;
  }

  if (facets["tags.name"] && facets["pageType"]) {
    return (
      <div className="searchPageFilters">
        {sortTags.map((el: any, index: number) => {
          if (el.category === "pageType") {
            return (
              <React.Fragment key={'category-' + index}>
                <div className="filter-container-inner-div">
                  {width > 992 ? (
                    <button aria-expanded={`${accordion[index] ? "false" : "true"}`} onClick={() => { handleAccordion(index) }}>
                      <span>{`${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`}</span>
                      <Accessibility location={`with ${Object.entries(el.tags[0]).length} list item`} />
                      <svg
                        className={`${accordion[index] ? "false" : "true"}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-12 -6 24 24"
                        width="23"
                        height="16"
                        type="image/svg+xml"
                        aria-expanded="false"
                      >
                        <title>{accordion[index] ? showContent : hideContent}</title>
                        <polyline points="-10,0 0,10 10,0"></polyline>
                      </svg>
                    </button>
                  ) : (
                    <span>{`${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`}</span>
                  )}
                </div>
                <div className={`filter-line ${accordion[index] ? "false" : "true"}`}>
                  {Object.entries(el.tags[0]).map((tag, index) => {
                    if (
                      //@ts-ignore
                      tag[1] !== 0
                    ) {
                      return (
                        <div key={'category-' + index} className="filter-checkbox">
                          <input
                            aria-label={`${getItemLabel(tag[0])} (${tag[1]})`}
                            onChange={() => handleInputChangePageType(tag[0])}
                            type={"checkbox"}
                            //@ts-ignore
                            checked={urlPageTypeTags?.includes(tag[0]) ? "checked" : ""}
                          />
                          <label className={"filter-label"} onClick={() => handleInputChangePageType(tag[0])}>
                            {`${getItemLabel(tag[0])} (${tag[1]})`}
                          </label>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                <div className="filter-container-inner-div">
                  {width > 992 ? (
                    <button aria-expanded={`${accordion[index] ? "true" : "false"}`} onClick={() => handleAccordion(index)}>
                      <span>{`${el.label ? el.label : el.category}`}</span>
                      <Accessibility location={JSON.stringify(el.tags[0]) !== JSON.stringify(el.tags[1]) ? `with ${Object.entries(el.tags[0]).length} list item` : `Empty Filter`} />
                      <svg
                        className={`${accordion[index] ? "true" : "false"}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-12 -6 24 24"
                        width="23"
                        height="16"
                        type="image/svg+xml"
                        aria-expanded="false"
                      >
                        <title>{accordion[index] ? showContent : hideContent}</title>
                        <polyline points="-10,0 0,10 10,0"></polyline>
                      </svg>
                    </button>
                  ) : (
                    <span>{`${el.label ? el.label : el.category}`}</span>
                  )}
                </div>
                <div
                  className={width > 992 ? `filter-line ${accordion[index] ? "true" : "false"}` : `filter-line true`}
                >
                  {Object.entries(el.tags[1]).map((tag, index) => {
                    if (JSON.stringify(el.tags[0]) !== JSON.stringify(el.tags[1])) {
                      return (
                        <>
                          {tag[1] !== 0 ? (
                            <div key={index} className="filter-checkbox">
                              <input
                                aria-label={`${tag[0]} (${tag[1]})`}
                                onChange={() => handleInputChange(tag[0])}
                                type={"checkbox"}
                                //@ts-ignore
                                checked={urlTags?.includes(tag[0]) ? "checked" : ""}
                              />
                              <label className={"filter-label"} onClick={() => handleInputChange(tag[0])}>
                                {`${tag[0]} (${tag[1]})`}
                              </label>
                            </div>
                          ) : null}
                        </>
                      );
                    } else {
                      return (
                        (index === 0 && <span className="filter-checkbox-empty">{filterNoResultsMessage}</span>) || null
                      );
                    }
                  })}
                </div>
              </React.Fragment>
            );
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default Filters;
